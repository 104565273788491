import { Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { For, Suspense } from 'solid-js';
import { gql } from '../../graphql';
import { Content } from '../../components/content';
import { cachedQuery } from '../../graphql/cached-get';
import { TeeTimeAlert } from '../../components/tee-time-alert';
import { PageHeader } from '../../components/page-header';
import type { TeeTimeAlertPreviewFragment } from '../../graphql';

export default function TeeTimeAlertsPage() {
	const data = createAsync(() => getTeeTimeAlerts({}));

	return (
		<Content>
			<Title>Tee Time Alerts | Troon</Title>
			<div class="mb-8 border-b border-neutral-300 pb-8 md:mb-12 md:pb-12">
				<PageHeader
					title="Tee time alerts"
					subtext="Get notified when a tee time becomes available that matches your preferences."
				/>
			</div>
			<h2 class="mb-6 text-xl font-semibold">Active tee time alerts</h2>
			<Suspense>
				<ul class="flex flex-col gap-4 md:gap-6">
					<For
						each={data()?.teeTimeAlerts}
						fallback={
							<li>There are no active tee time alerts. When you create a new alert, it will be available here.</li>
						}
					>
						{(alert) => (
							<li class="rounded border border-neutral p-4 md:p-6">
								<TeeTimeAlert teeTimeAlert={alert as TeeTimeAlertPreviewFragment} editable />
							</li>
						)}
					</For>
				</ul>
			</Suspense>
		</Content>
	);
}

const query = gql(`
query teeTimeAlerts {
  teeTimeAlerts(isActive: true) {
    ...TeeTimeAlertPreview
  }
}
`);

const getTeeTimeAlerts = cachedQuery(query, {});
